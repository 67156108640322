<template>
  <div v-if="document">
    <div class="wrapper">
      <div class="document-header">
        <div class="logo">
          <img src="@/assets/petnet_logo.png" alt="">
        </div>
        <div class="document-title">
          INDIVIDUAL PERFORMANCE MANAGEMENT FORM
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <td colspan="24" class="gray">
              <h3>A. EMPLOYEE INFORMATION</h3>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <p><b>Employee Name</b></p>
            </td>
            <td colspan="6">
              <p>{{document.employee_details.em_id_name}}</p>
            </td>
            <td colspan="6">
              <p><b>Department</b></p>
            </td>
            <td colspan="6">
              <p>{{document.employee_details.bd_id_name}}</p>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <p><b>Position</b></p>
            </td>
            <td colspan="4">
              <p>{{document.employee_details.bp_id_name}}</p>
            </td>
            <td colspan="4">
              <p><b>Level</b></p>
            </td>
            <td colspan="4">
              <p></p>
            </td>
            <td colspan="4">
              <p><b>Evaluation Period</b></p>
            </td>
            <td colspan="4">
              <p></p>
            </td>
          </tr>
          <tr>
            <td colspan="24">
              <p>Type of Evaluation (Please check the appropriate box:)</p>
              <div style="pointer-events: none">
                <el-checkbox>3rd Month</el-checkbox>
                <el-checkbox>5th Month</el-checkbox>
                <el-checkbox>Mid Year</el-checkbox>
                <el-checkbox>Year End</el-checkbox>
                <el-checkbox>Special</el-checkbox>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="24" class="gray">
              <h3>B. Instructions:</h3>
            </td>
          </tr>
          <tr>
            <td colspan="24">
              <ol>
                <li v-for="instruction in document.instruction" :key="instruction.id">
                  <p>{{ instruction.name }}</p>
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="gray">
              <h3>C. RATING</h3>
            </td>
            <td colspan="10" class="blue">
              <h3>WHAT RATING SCALE (KPI/TARGETS)</h3>
            </td>
            <td colspan="10" class="yellow">
              <h3>HOW RATING SCALE (CORE VALUES & LEADERSHIP DIMENSIONS)</h3>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <h3>1</h3>
            </td>
            <td colspan="4" class="blue">
              <h3>OUTSTANDING </h3>
            </td>
            <td colspan="7">
              <p><b>FAR EXCEEDS expectations and requirements</b> by delivering exceptional results vs targets and models the highest standards of how work is accomplished across organizational boundaries and within teams. Sought and adapted to increasingly challenging work.</p>
            </td>
            <td colspan="4" class="yellow">
              <h3>ROLE MODEL</h3>
            </td>
            <td colspan="7">
              <p><b>LIVES</b> the core values and leadership practices within and outside work. A CLEAR ADVOCATE of the core values and leadership practices. </p>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <h3>2</h3>
            </td>
            <td colspan="4" class="blue">
              <h3>HIGHLY EFFECTIVE</h3>
            </td>
            <td colspan="7">
              <p><b>Performance consistently met and frequently exceeded objectives and expectations.</b> Adapted to increasingly challenging work while surpassing targets</p>
            </td>
            <td colspan="4" class="yellow">
              <h3>INFLUENCERS</h3>
            </td>
            <td colspan="7">
              <p><b>EMBRACES</b> the core values / leadership practices and INFLUENCES others to follow his / her lead</p>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <h3>3</h3>
            </td>
            <td colspan="4" class="blue">
              <h3>EFFECTIVE</h3>
            </td>
            <td colspan="7">
              <p><b>Performance consistently met objectives and expectations,</b> delivering the requirement of the role</p>
            </td>
            <td colspan="4" class="yellow">
              <h3>ALIGNED</h3>
            </td>
            <td colspan="7">
              <p><b>CONSISTENTLY</b> behaves and acts in alignment with the core values and leadership practices</p>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <h3>4</h3>
            </td>
            <td colspan="4" class="blue">
              <h3>MARGINAL</h3>
            </td>
            <td colspan="7">
              <p><b>INCONSISTENT DELIVERY.</b> Performance met some objectives & expectations but fails to deliver on others.</p>
            </td>
            <td colspan="4" class="yellow">
              <h3>INCONSISTENT</h3>
            </td>
            <td colspan="7">
              <p><b>OCASSIONALLY</b> exhibits the core values and leadership practices and requires frequent reminder to do so.</p>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <h3>5</h3>
            </td>
            <td colspan="4" class="blue">
              <h3>UNSATISFACTORY</h3>
            </td>
            <td colspan="7">
              <p><b>FAR EXCEEDS expectations and requirements</b> by delivering exceptional results vs targets and models the highest standards of how work is accomplished across organizational boundaries and within teams. Sought and adapted to increasingly challenging work.</p>
            </td>
            <td colspan="4" class="yellow">
              <h3>CHALLENGED</h3>
            </td>
            <td colspan="7">
              <p><b>DOES NOT often</b> display acts and behaviors aligned with the core values and leadership practices and is disruptive to others who wants to adhere to the core values/leadership practices.</p>
            </td>
          </tr>
          <tr>
            <td colspan="24" class="blue-gray">
              <h3>D. WHAT FACTORS (70%) - INDIVIDUAL PERFORMANCE PLAN (PERFORMANCE OBJECTIVES) (As jointly agreed upon by subordinate and Immediate Superior/Department Head)</h3>
            </td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td colspan="4">
              <p>BSC Perspective</p>
            </td>
            <td colspan="4">
              <p>Key Performance Indicators (KPI)</p>
            </td>
            <td colspan="2">
              <p>Target</p>
            </td>
            <td colspan="6">
              <p>Actual Result</p>
            </td>
            <td colspan="2">
              <p>% Weight</p>
            </td>
            <td colspan="2">
              <p>Rating</p>
            </td>
            <td colspan="2">
              <p>Weighted Rating</p>
            </td>
          </tr>
          <tr v-for="factor in document.factors" :key="factor.id">
            <td colspan="2">
              <p>{{ factor.id }}</p>
            </td>
            <td colspan="4">
              <p>{{ factor.perspective }}</p>
            </td>
            <td colspan="4">
              <p></p>
            </td>
            <td colspan="2">
              <p>{{ factor.weight_percentage }}</p>
            </td>
            <td colspan="6">
              <p></p>
            </td>
            <td colspan="2">
              <p>{{ factor.target_percentage }}</p>
            </td>
            <td colspan="2">
              <p></p>
            </td>
            <td colspan="2">
              <p></p>
            </td>
          </tr>
          <tr>
            <td colspan="18"></td>
            <td colspan="2" class="blue-gray">
              <p>test</p>
            </td>
            <td colspan="2" class="blue-gray"></td>
            <td colspan="2" class="blue-gray">
              <p>test</p>
            </td>
          </tr>
          <tr>
            <td colspan="24" class="gray">
              <p>If there is any objective which was agreed upon after the Individual Performance Planning, please add as an item. </p>
            </td>
          </tr>
          <tr>
            <td colspan="24">
              <p>test</p>
            </td>
          </tr>
          <tr>
            <td colspan="24" class="yellow">
              <h3>E. HOW FACTORS (30%) CORE VALUES & LEADERSHIP DIMENSIONS (Show how goals are achieved in alignment with company's core values and leadership practices)</h3>
            </td>
          </tr>
          <template v-for="factor in document.factors_main">
            <tr :key="factor.id" class="gray">
              <td colspan="24">
                <h3>{{ factor.name }}</h3>
              </td>
            </tr>
            <tr :key="factor.name">
              <td colspan="8">
                <h3>QUALITIES</h3>
              </td>
              <td colspan="2">
                <h3>% Weighted</h3>
              </td>
              <td colspan="2">
                <h3>Rating</h3>
              </td>
              <td colspan="4">
                <h3>Weighted Rating</h3>
              </td>
              <td colspan="4">
                <h3>Summary of Demonstrated Strengths / Achievements</h3>
              </td>
              <td colspan="4">
                <h3>Summary of Identified Development Needs</h3>
              </td>
            </tr>
            <template v-for="factor_item in factor.items">
              <tr :key="factor_item.id">
                <td colspan="8" class="yellow">
                  <h3>{{ factor_item.name }}</h3>
                </td>
                <td rowspan="2" colspan="2">{{factor_item.weight_percentage}}</td>
                <td rowspan="2" colspan="2"></td>
                <td rowspan="2" colspan="4"></td>
                <td rowspan="2" colspan="4"></td>
                <td rowspan="2" colspan="4"></td>
              </tr>
              <tr :key="factor_item.name">
                <td colspan="8">
                  <ol>
                    <li v-for="factor_list in factor_item.items" :key="factor_list.id">
                      <p>{{ factor_list.name }}</p>
                    </li>
                  </ol>
                </td>
              </tr>
            </template>
          </template>
          <tr>
            <td colspan="24" class="gray">
              <h3>F. OVERALL PERFORMANCE RATING</h3>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <h3>PERFORMANCE FACTORS</h3>
            </td>
            <td colspan="3">
              <h3>RATING</h3>
            </td>
            <td colspan="3">
              <h3>WEIGHT</h3>
            </td>
            <td colspan="3">
              <h3>FINAL RATING</h3>
            </td>
            <td colspan="7">
              <h3>PERFORMANCE SUMMARY NOTES</h3>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <p>II. HOW (Core Values & Leadership Practices)</p>
            </td>
            <td colspan="3">
              <p></p>
            </td>
            <td colspan="3">
              <p></p>
            </td>
            <td colspan="3">
              <p></p>
            </td>
            <td colspan="7" rowspan="3">
              <p></p>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <p>I. WHAT (Performance Objectives)</p>
            </td>
            <td colspan="3">
              <p></p>
            </td>
            <td colspan="3">
              <p></p>
            </td>
            <td colspan="3">
              <p></p>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <h3>OVERALL PERFORMANCE RATING (Sum of all Weighted Values)</h3>
            </td>
            <td colspan="6" class="gray">
              <p></p>
            </td>
            <td colspan="3">
              <p></p>
            </td>
          </tr>
          <tr>
            <td v-for="overall in document.overall" :key="overall.id" colspan="12" class="gray">
              <h3>{{overall.name}}</h3>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <p></p>
            </td>
            <td colspan="12">
              <p></p>
            </td>
          </tr>
          <tr>
            <td colspan="24" class="green">
              <h3>G. INDIVIDUAL DEVELOPMENT PLAN</h3>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <h3>Key Performance Areas</h3>
            </td>
            <td colspan="4">
              <h3>Development Needs</h3>
            </td>
            <td colspan="4">
              <h3>Action Plan</h3>
            </td>
            <td colspan="4">
              <h3>Target Completion Date</h3>
            </td>
            <td colspan="4">
              <h3>Status</h3>
            </td>
            <td colspan="4">
              <h3>Remarks</h3>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <p><i>What areas or aspects of the employee's performance requires further enhancement (e.g. Reporting Accuracy, Sales Productivity, etc)</i></p>
            </td>
            <td colspan="4">
              <p><i>To enhance performance, what specific competency areas (skills, knowledge, and attitude) need further development? </i></p>
            </td>
            <td colspan="4">
              <p><i>What arethe major learning activities required to address the development need. Activities could range from self-directed learning, e-learning, mentoring, coaching, attending training, special projects, exposure to different functions, etc.</i></p>
            </td>
            <td colspan="4">
              <p><i>When will the action item be completed?</i></p>
            </td>
            <td colspan="4">
            </td>
            <td colspan="4">
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <h3>EMPLOYEE'S COMMENTS on his/her appraisal and rating:</h3>
            </td>
            <td colspan="12">
              <h3>IMMEDIATE SUPERIOR'S COMMENTS:</h3>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <span></span>
            </td>
            <td colspan="12">
              <span></span>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <p>I confirm that my immediate superior/department head personally discussed with me the results of this performance evaluation and appraisal and I agree with the rating in this form.</p>
            </td>
            <td colspan="12">
              <p>I confirm that I personally discussed this appraisal to the concerned staff</p>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <span></span>
            </td>
            <td colspan="12">
              <span></span>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <p><center>Signature over printed name</center></p>
            </td>
            <td colspan="12">
              <p><center>Signature over printed name</center></p>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <p><b>RECOMMENDING APPROVAL:</b></p>
            </td>
            <td colspan="12">
              <p>Department Head Comments (No need to fill this in if the rater is the Department Head)</p>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <p><b><center>Department Head / Manager</center></b></p>
            </td>
            <td colspan="12">
              <span></span>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <p><b>For HR Use only</b></p>
              <p>Date Received:</p>
              <p>Received by:</p>
              <p>Calibrated by:</p>
            </td>
            <td colspan="12">
              <p><b>HR Remarks</b></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    document: {
      type: Object,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    background-color: #fff;
    padding: 2rem;
    font-size: 12px;
    color: black;
  }
  .document-header {
    display: flex;
    align-items: center;
    .logo {
      padding-left: 2rem;
      width: 250px;
      height: auto;
      img {
        height: auto;
        width: 100%;
      }
    }
  }
  .document-title {
    font-weight: bold;
    border: 1px solid black;
    padding: .5rem;
    width: 100%;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    word-break: break-word;
  }
  ol {
    margin: 0;
    padding-left: 2rem;
  }
  table, th, td {
    border-collapse: collapse;
    border: 1px solid black;
  }
  table {
    width: 100%;
    table-layout: fixed;
    tr {
      height: 20px;
      &.padding {
        padding: 1rem;
      }
      td {
        padding: 0 1rem;
        &.gray {
          background-color: rgb(212, 212, 212);
        }
        &.blue {
          background-color: rgb(151, 195, 216);
        }
        &.blue-gray {
          background-color: rgb(152, 190, 233);
        }
        &.yellow {
          background-color: rgb(255, 229, 111);
        }
        &.green {
          background-color: rgb(140, 255, 140);
        }
      }
    }
  }
</style>
