<template>
  <div v-if="document">
    <div class="wrapper">
      <div class="document-header">
        <div class="logo">
          <img src="@/assets/petnet_logo.png" alt="">
        </div>
        <div class="document-title">
          NOTICE OF PERSONNEL ACTION (NPA) FORM
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <td colspan="12">
              <div class="document">
                <div class="document-subtitle">
                  <p>PURPOSE OF NPA: To officially document, validate, and confirm all personnel action processed by HRDD.</p>
                  <p>Directions:</p>
                  <ol>
                    <li><p>HRD: Fill-in all spaces with correct and precise information relative to designation of the employee.</p></li>
                    <li><p>SIGNATORIES: Affix your signature to confirm acceptance of all employment terms of the employee.</p></li>
                  </ol>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3"><b>EMPLOYEE ID NO</b></td>
            <td colspan="9">{{document.employee_details.empid}}</td>
          </tr>
          <tr>
            <td colspan="3"><b>HIRING DATE</b></td>
            <td colspan="9">{{document.employee_details.datehired}}</td>
          </tr>
          <tr>
            <td colspan="3"><b>NAME</b></td>
            <td colspan="9">{{document.employee_details.name}}</td>
          </tr>
          <tr>
            <td colspan="3"><b>DEPARTMENT</b></td>
            <td colspan="9">{{document.employee_details.bd_id_name}}</td>
          </tr>
          <tr>
            <td colspan="3"><b>EMPLOYEE STATUS</b></td>
            <td colspan="9">{{document.employee_details.bes_id_name}}</td>
          </tr>
          <tr>
            <td colspan="12">
              <div class="border">
                <b><p style="font-size: 1.1rem;">NATURE OF PERSONNEL ACTION: {{document.employee_details.npa_bg4_id_name}}</p></b>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <i><b><p style="font-size: 1.1rem;">EFFECTIVE DATE OF PERSONNEL ACTION: {{ formatDate(document.employee_details.effectivity_date_to, 'MMMM dd, yyyy') }}</p></b></i>
            </td>
          </tr>
          <tr style="text-align: center;">
            <td colspan="4"><b>FROM</b></td>
            <td colspan="4"><b>ITEM</b></td>
            <td colspan="4"><b>TO</b></td>
          </tr>
          <tr style="text-align: center;" v-for="item in document.new_employee_details" :key="item.bmen_id">
            <td colspan="4">{{item.old_name}}</td>
            <td colspan="4">{{item.description}}</td>
            <td colspan="4">{{item.new_name}}</td>
          </tr>
          <tr>
            <td colspan="12">
              <b><p align="center">OFFICIAL SIGNATORIES</p></b>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <div class="signature">
                <p>Endorsed By:</p>
                <div class="signatory">
                  <div v-if="!document.approvers['1275']">
                    <div v-for="(approver, index) in document.approvers['1373']" :key="index" class="sign">
                      <b><p style="padding-top: 0; margin: 0; word-break: break-word;">{{approver.file_status}}</p></b>
                      <p class="date" v-if="getSigned(approver.a_date_action,approver.boss_em_id_name_married_fi_ln)">{{getSigned(approver.a_date_action,approver.boss_em_id_name_married_fi_ln)}}</p>
                      <div class="field"></div>
                      <b><p style="padding-top: .5rem; margin: 0; word-break: break-word;">{{approver.boss_em_id}}</p></b>
                      <p style="margin: 0;">{{approver.boss_bp_id_name}}</p>
                    </div>
                  </div>
                  <div v-else-if="document.approvers['1275']">
                    <div v-for="(approver, index) in document.approvers['1275']" :key="index" class="sign">
                      <b><p style="padding-top: 0; margin: 0; word-break: break-word;">{{approver.file_status}}</p></b>
                      <p class="date" v-if="getSigned(approver.a_date_action,approver.boss_em_id_name_married_fi_ln)">{{getSigned(approver.a_date_action,approver.boss_em_id_name_married_fi_ln)}}</p>
                      <div class="field"></div>
                      <b><p style="padding-top: .5rem; margin: 0; word-break: break-word;">{{approver.boss_em_id}}</p></b>
                      <p style="margin: 0;">{{approver.boss_bp_id_name}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="4">
              <!-- <b><p align="center">OFFICIAL SIGNATORIES</p></b> -->
              <div class="signatory">
                <div v-if="document.approvers['1275']">
                    <div v-for="(approver, index) in document.approvers['1373']" :key="index" class="sign">
                      <b><p style="padding-top: 0; margin: 0; word-break: break-word;">{{approver.file_status}}</p></b>
                      <p class="date" v-if="getSigned(approver.a_date_action,approver.boss_em_id_name_married_fi_ln)">{{getSigned(approver.a_date_action,approver.boss_em_id_name_married_fi_ln)}}</p>
                      <div class="field"></div>
                      <b><p style="padding-top: .5rem; margin: 0; word-break: break-word;">{{approver.boss_em_id}}</p></b>
                      <p style="margin: 0;">{{approver.boss_bp_id_name}}</p>
                    </div>
                  </div>
                </div>
            </td>
            <td colspan="4">
              <div class="signature">
                <p>Recommending Approval</p>
                <div class="signatory">
                  <div v-for="(approver, index) in document.approvers['1276']" :key="index" class="sign">
                    <b><p style="padding-top: 0; margin: 0; word-break: break-word;">{{approver.file_status}}</p></b>
                    <p class="date" v-if="getSigned(approver.a_date_action,approver.boss_em_id_name_married_fi_ln)">{{getSigned(approver.a_date_action,approver.boss_em_id_name_married_fi_ln)}}</p>
                    <div class="field"></div>
                    <b><p style="padding-top: .5rem; margin: 0; word-break: break-word;">{{approver.boss_em_id}}</p></b>
                    <p style="margin: 0;">{{approver.boss_bp_id_name}}</p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <p class="approved-by">Approved By:</p>
              <div class="approvers">
                <div v-for="(approver, index) in document.approvers['1277']" :key="index" class="sign">
                  <b><p style="padding-top: 0; margin: 0; word-break: break-word;">{{approver.file_status}}</p></b>
                  <p class="date" v-if="getSigned(approver.a_date_action,approver.boss_em_id_name_married_fi_ln)">{{getSigned(approver.a_date_action,approver.boss_em_id_name_married_fi_ln)}}</p>
                  <div class="field"></div>
                  <b><p style="padding-top: .5rem; margin: 0; word-break: break-word;">{{approver.boss_em_id}}</p></b>
                  <p style="margin: 0;">{{approver.boss_bp_id_name}}</p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <i><p style="text-align: center; margin-bottom: 1rem;">By my signatory below, I confirm, agree to, and accept all terms and conditions contained in this Notice of Personnel Action Form</p></i>
              <div class="signatory">
                <div class="sign">
                  <b><p class="date" v-if="getAcknowledgeByStatus(document.employee_details.em_id_acknowledgement_date,document.employee_details.name)">{{getAcknowledgeByStatus(document.employee_details.em_id_acknowledgement_date, document.employee_details.name)}}</p></b>
                  <p class="date" v-if="getAcknowledgeBy(document.employee_details.em_id_acknowledgement_date,document.employee_details.name)">{{getAcknowledgeBy(document.employee_details.em_id_acknowledgement_date, document.employee_details.name)}}</p>
                  <div class="field"></div>
                  <p>Signature of Employee</p>
                </div>
                <div class="sign">
                  <p class="date" v-if="getAcknowledge(document.employee_details.em_id_acknowledgement_date)">{{getAcknowledge(document.employee_details.em_id_acknowledgement_date)}}</p>
                  <div class="field"></div>
                  <p>Date Signed</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  props: {
    document: {
      type: Object,
      required: false,
      default: null
    }
  },
  methods: {
    getSigned (date, name) {
      if (date) {
        return name + ' ' + format(new Date(date), 'MMMM dd, yyyy hh:mm a')
      }
      return ' '
    },
    getAcknowledgeBy (date, name) {
      if (date) {
        return name
      }
      return ' '
    },
    getAcknowledgeByStatus (date, name) {
      if (date) {
        return 'Acknowledged'
      }
      return ' '
    },
    getAcknowledge (date) {
      if (date) {
        return format(new Date(date), 'MMMM dd, yyyy hh:mm a')
      }
      return ' '
    },
    formatDate (date, dateFormat = 'MMMM dd, yyyy hh:mm a') {
      if (date) {
        return format(new Date(date), dateFormat)
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    background-color: #fff;
    padding: 2rem;
    font-size: 12px;
  }
  .document-header {
    display: flex;
    align-items: center;
    .logo {
      padding-left: 2rem;
      width: 250px;
      height: auto;
      img {
        height: auto;
        width: 100%;
      }
    }
  }
  .document-title {
    font-weight: bold;
    border: 1px solid black;
    padding: .5rem;
    width: 100%;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .document {
    display: block;
  }
  ol p {
    margin: 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    td, th {
      border: 1px solid black;
      padding: .5rem;
      p {
        margin: 0;
      }
    }
  }
  .signature {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 120px;
  }
  .signatory {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    p {
      text-align: center;
    }
  }
  .date {
    margin-top: 1rem;
  }
  .approvers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .sign {
    text-align: center;
    // padding: 2rem 0 0 0;
  }
  .field {
    width: 200px;
    border-top: 1px solid black;
  }
</style>
